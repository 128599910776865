import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useState } from 'react'

import { Props as RoomProps, RoomModal } from './modal'

export interface Props {
  images?: ImageProps[]
  title?: string
  guests?: string
  size?: string
  pillow_menu?: boolean
  double_bathroom?: boolean
  description?: string
  featuresList?: any
  filtersGroup?: string
  languageCode: string
  className?: string
}

export const RoomItem = memo(function RoomItem({
  images,
  title,
  guests,
  size,
  pillow_menu,
  double_bathroom,
  description,
  featuresList,
  filtersGroup,
  languageCode,
  className,
  idx,
}: RoomProps) {
  const [modalStatus, setModalStatus] = useState('closed')
  const [_locked, setLocked] = useLockedBody()

  const onClose = () => {
    setModalStatus('closed')
    setLocked(false)
  }
  return (
    <>
      <Container className={className}>
        <FadeInUp delay={idx ? (idx % 2) * 0.15 : 0}>
          <WrapperTop>
            {images ? <StyledImage {...images[0]} /> : null}
            <Overlay />
            <ExtraFeatures>
              {double_bathroom ? (
                <ExtraFeature>
                  <img src="/icon_filter-rooms-bath.svg" alt="ico-bath" />
                  {useVocabularyData('rooms-filters-bath', languageCode)}
                </ExtraFeature>
              ) : null}
              {pillow_menu ? (
                <ExtraFeature>
                  <img src="/icon_filter-rooms-pillow.svg" alt="ico-pillow" />
                  {useVocabularyData('rooms-filters-pillow', languageCode)}
                </ExtraFeature>
              ) : null}
            </ExtraFeatures>
          </WrapperTop>
          <WrapperInfos>
            <Pretitle>{useVocabularyData('room', languageCode)}</Pretitle>
            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
            <Info>
              <img src="/icon_room-guests.svg" alt="ico-guest" /> {guests}
            </Info>
            <Info>
              <img src="/icon_room-size.svg" alt="ico-size" /> {size}
            </Info>
            <Buttons>
              <StyledButton
                URL={useVocabularyData('booking-engine-url', languageCode)}
                target="_blank"
                label={useVocabularyData('book-now', languageCode)}
              />
              <StyledButton
                label={useVocabularyData('details', languageCode)}
                variant="outline-dark"
                onClick={() => {
                  setModalStatus('open')
                  setLocked(true)
                }}
              />
            </Buttons>
          </WrapperInfos>
        </FadeInUp>
      </Container>

      <RoomModal
        {...{
          images,
          title,
          guests,
          size,
          pillow_menu,
          double_bathroom,
          description,
          featuresList,
          filtersGroup,
          languageCode,
          className,
          modalStatus,
          onClose,
        }}
      />
    </>
  )
})

const Container = styled.article`
  width: 50%;
  padding: 0 3vw;
  margin-bottom: 6vw;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
`

const WrapperTop = styled.div`
  position: relative;
`

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  );
`

const ExtraFeatures = styled.div`
  position: absolute;
  bottom: 2vw;
  right: 1.5vw;

  @media (max-width: 991px) {
    bottom: 15px;
    right: 15px;
  }
`
const ExtraFeature = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 0 1vw;
  font-size: 0.7vw;
  border-right: 1px solid rgba(255, 255, 255, 0.2);

  &:last-child {
    border: none;
  }

  img {
    filter: brightness(1000%);
    margin-right: 10px;
    width: 1vw;
    vertical-align: middle;
    transform: translateY(-2px);
  }

  @media (max-width: 1439px) {
    font-size: 12px;
    padding: 0 10px;
    img {
      width: 20px;
    }
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 23vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const WrapperInfos = styled.div`
  padding: 4vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
  }
`
const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled.h3`
  font-size: 2vw;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 10px 0;
  }
`
const Info = styled.div`
  font-size: 1vw;
  padding: 1vw 0;
  font-weight: 300;
  border-bottom: 1px dotted ${({ theme }) => theme.colors.variants.neutralDark2};

  img {
    width: 0.9vw;
    vertical-align: middle;
    margin-right: 0.5vw;
    transform: translateY(-2px);
  }

  @media (max-width: 991px) {
    font-size: 16px;
    padding: 10px 0;
    img {
      margin-right: 10px;
      width: 14px;
    }
  }
`

const Buttons = styled.div`
  margin-top: 3vw;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`
const StyledButton = styled(Button)`
  margin-right: 2vw;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 991px) {
    margin-top: 10px;
  }
`
